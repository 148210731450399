import React from 'react';

import { Typography, AppBar, Toolbar } from '@mui/material';
//import MenuIcon from '@mui/icons-material/Menu';

function MyBar() {
    return (
      <AppBar position="static" sx={{ backgroundColor: '#D8EDF9' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, color: '#112B68' }}>
            CHECK LIST
          </Typography>
          <img src="/LOGO-FRANGENTE-small.png" alt="Logo" style={{ height: 40 }} />
        </Toolbar>
      </AppBar>
    );
}

export default MyBar;

