import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

const TodoForm = ({ addTodo }) => {
  const [value, setValue] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (!value) return;
    addTodo(value);
    setValue('');
  };

  return (
    <form onSubmit={handleSubmit} className="todo-form">
      <TextField
        label="Aggiungi alla lista"
        variant="outlined"
        value={value}
        onChange={e => setValue(e.target.value)}
        fullWidth
      />
      <Button variant="contained" sx={{ backgroundColor: '#D8EDF9', color: '#112B68' }} type="submit">
        Aggiungi
      </Button>
    </form>
  );
};

export default TodoForm;
