import React from 'react';
import TodoItem from './TodoItem';

const TodoList = ({ todos, removeTodo, toggleTodo }) => (
  <ul className="todo-list">
    {todos.map((todo, index) => (
      <TodoItem
        key={index}
        index={index}
        todo={todo}
        removeTodo={removeTodo}
        toggleTodo={toggleTodo}
      />
    ))}
  </ul>
);

export default TodoList;
