import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
//commentati per non avere service worker che inibiscano l'uso dell'app con chrome. Intanto non puo' andare app senza rete perche'
//non c'e' controllo token

// serviceWorkerRegistration.register({
//   onUpdate: registration => {
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//     }
//   }
// });

reportWebVitals();

window.addEventListener('online', () => {
  console.log('Rete disponibile, aggiornamento della pagina...');
  window.location.reload();
});
