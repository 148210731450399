import React from 'react';
import { IconButton, Checkbox, ListItem, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const TodoItem = ({ todo, index, removeTodo, toggleTodo }) => {
  const handleRemoveClick = (e) => {
    e.stopPropagation();
    removeTodo(index);
  };

  return (
    <ListItem className="todo-item" key={index} dense button onClick={() => toggleTodo(index)}>
      <Checkbox
        edge="start"
        checked={todo.completed}
        tabIndex={-1}
        sx={{
          // color: "pink",
          '&.Mui-checked': {
          color: "#91c2eb",
           },
         }}
        disableRipple
      />
      <ListItemText primary={todo.text} className={todo.completed ? 'completed' : ''} />
      <IconButton edge="end" aria-label="delete" onClick={handleRemoveClick} style={{
          color: "#91c2eb",
        }}>
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
};

export default TodoItem;
