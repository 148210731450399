export const defaultLists = {
    bambini: [
        { text: '4 magliette a manica corta', completed: false },
        { text: '1 maglia a manica lunga', completed: false },
        { text: '1 camicia o maglietta elegante (per i bambini)', completed: false },
        { text: '1 pantaloncino leggero', completed: false },
        { text: '3 vestitini (per le bambine)', completed: false },
        { text: '1 shorts di jeans ', completed: false },
        { text: '1 pantalone della tuta o 1 paio di leggins', completed: false },
        { text: '1 felpa o pile', completed: false },
        { text: '1 golfino di lana', completed: false },
        { text: '1 maglia termica a manica lunga ', completed: false },
        { text: '4 costumi da bagno ', completed: false },
        { text: '1 maglia a protezione solare', completed: false },
        { text: '1 maglia termica per il mare', completed: false },
        { text: '4 mutande ', completed: false },
        { text: '1 paio di calzini', completed: false },
        { text: '1 maglia per dormire o 1 pigiama corto', completed: false },
        { text: '1 accappatoio o telo in microfibra per acqua dolce ', completed: false },
        { text: '1 telo mare in microfibra per acqua salata', completed: false },
        { text: '1 giacca impermeabile-antivento-traspirante', completed: false },
        { text: 'sandali da trekking ', completed: false },
        { text: 'ciabatte di gomma ', completed: false },
        { text: 'scarpe chiuse', completed: false },
        { text: '1 o 2 cappelli con visiera ', completed: false },
        { text: 'occhiali da sole con lenti polarizzate', completed: false },
        { text: '1 pareo', completed: false },
        { text: 'abbigliamento e attrezzatura sportiva se previsto', completed: false }

    ],
    neonati: [
        { text: '4 body non troppo stretti', completed: false },
        { text: '3 maglie oversize', completed: false },
        { text: '2 pantaloncini', completed: false },
        { text: '2 magliettine o due vestitini', completed: false },
        { text: '1 tutina intera', completed: false },
        { text: '2 felpa o golfino lana', completed: false },
        { text: '1 giacchino antivento', completed: false },
        { text: '3 bavaglie', completed: false },
        { text: '1 bavaglia di plastica lavabile per la pappa', completed: false },
        { text: 'thermos per tenere latte e pappa al caldo', completed: false },
        { text: '1 cappellino in jersey per la sera e 1 cappellino da pescatore per il giorno', completed: false },
        { text: 'occhiali da sole', completed: false },
        { text: 'costume pannolino per fare il bagno in spiaggia', completed: false },
        { text: 'scorta di pannolini e salviettine umidificate', completed: false },
        { text: 'ciucci e latte in polvere se previsto', completed: false }
    ],
    beauty: [
        { text: 'detergente ecologico ', completed: false },
        { text: 'spazzolino di bamboo', completed: false },
        { text: 'dentifricio', completed: false },
        { text: 'protezione solare totale', completed: false },
        { text: 'doposole', completed: false },
        { text: 'salviettine umidificate antibatteriche', completed: false },
        { text: 'elastici e spazzola', completed: false }
    ],
    farmacia: [
        { text: 'antistaminico per reazioni allergiche o punture di insetti', completed: false },
        { text: 'antipiretico in caso di febbre', completed: false },
        { text: 'analgesico per mal di testa', completed: false },
        { text: 'antidiarroico,da abbinare ai fermenti lattici', completed: false },
        { text: 'antiemetico per combattere il mal di mare', completed: false },
        { text: 'antibiotico generico, in sciroppo e in pomata', completed: false },
        { text: 'cortisonici', completed: false },
        { text: 'arnica per gli ematomi', completed: false },
        { text: 'sciroppo per la tosse', completed: false },
        { text: 'propoli per la gola', completed: false },
        { text: 'gocce per le orecchie', completed: false },
        { text: 'collirio semplice per le irritazioni, e uno con antibiotico per le congiuntiviti', completed: false },
        { text: 'integratori a base di zenzero per il mal di mare', completed: false },
        { text: 'termometro digitale', completed: false },
        { text: 'vitamine e sali minerali', completed: false }
    ],
    nondimenticare: [
        { text: 'documenti: carta d’identità o passaporto validi', completed: false },
        { text: 'tessera sanitaria', completed: false },
        { text: 'libretto vaccinale', completed: false },
        { text: 'un’assicurazione sanitaria privata temporanea', completed: false },
        { text: 'eventuali permessi per far viaggiare i minori senza i genitori', completed: false }

    ]
};
