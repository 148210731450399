import React, { useState, useEffect } from 'react';
import { Box, Stack, Container, Typography, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from '@mui/material/Link';
import TodoForm from './components/TodoForm';
import TodoList from './components/TodoList';
import MyBar from './components/MyBar.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './App.css';

import { defaultLists } from './defaultLists.js';
//prova per disattivare serviceWorker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

function App() {
  const [selectedList, setSelectedList] = useState('neonati');
  const [lists, setLists] = useState(() => {
    try {
      const storedLists = JSON.parse(localStorage.getItem('lists'));
      return storedLists || defaultLists;
    } catch (error) {
      console.error('Errore nel caricamento delle liste dal Local Storage:', error);
      return defaultLists;
    }
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // Aggiungi stato di loading

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      localStorage.setItem('token', token);
      verifyToken(token);
    } else {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        verifyToken(storedToken);
      } else {
        setLoading(false); // Fine del caricamento se non c'è token
      }
    }
  }, []);

  const verifyToken = (token) => {
    fetch('/api/verify-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.valid) {
          setIsLoggedIn(true);
          // Cambia l'URL senza ricaricare la pagina
          window.history.replaceState({}, document.title, '/');
        } else {
          localStorage.removeItem('token');
        }
      })
      .catch((error) => {
        console.error('Errore nella verifica del token:', error);
      })
      .finally(() => {
        setLoading(false); // Fine del caricamento dopo la verifica del token
      });
  };

  useEffect(() => {
    try {
      localStorage.setItem('lists', JSON.stringify(lists));
    } catch (error) {
      console.error('Errore nel salvataggio delle liste nel Local Storage:', error);
    }
  }, [lists]);

  const addTodo = (text) => {
    const newTodos = [...lists[selectedList], { text, completed: false }];
    setLists({ ...lists, [selectedList]: newTodos });
  };

  const removeTodo = (index) => {
    const newTodos = [...lists[selectedList]];
    newTodos.splice(index, 1);
    setLists({ ...lists, [selectedList]: newTodos });
  };

  const toggleTodo = (index) => {
    const newTodos = [...lists[selectedList]];
    newTodos[index].completed = !newTodos[index].completed;
    setLists({ ...lists, [selectedList]: newTodos });
  };

  const resetTodos = () => {
    setLists({ ...lists, [selectedList]: defaultLists[selectedList] });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(lists[selectedList]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TodoList');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'TodoList.xlsx');
  };

  if (loading) {
    // Renderizza uno schermo di caricamento finché il controllo del token non è completato
    return (
      <>
      </>
    );
  }

  if (!isLoggedIn) {
    return (
      <><MyBar></MyBar><Container maxWidth="sm" className="main-container">
        <img src="/header3.png" alt="Header" style={{ height: 190 }} />

        <Typography variant="h6" gutterBottom>
          Inserisci il tuo indirizzo email per accedere:
        </Typography>
        <input
          type="email"
          placeholder="Email"
          id="email"
          style={{ width: '100%', padding: '8px', marginBottom: '10px' }} />
        <Button
          variant="contained"
          sx={{ backgroundColor: '#112B68', color: '#ffffff' }}
          onClick={() => {
            const email = document.getElementById('email').value;
            fetch('/api/send-token', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email }),
            })
              .then((response) => response.json())
              .then((data) => {
                alert(data.message);
              })
              .catch((error) => {
                console.error('Errore nell\'invio dell\'email:', error);
              });
          }}
        >
          Invia mail
        </Button>
      </Container></>
    );
  }

  return (
    <div>
      <MyBar></MyBar>
      <Container maxWidth="sm" className="main-container">
        <img src="/header3.png" alt="Header" style={{ height: 190 }} />
        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <InputLabel id="select-list-label">Seleziona Lista</InputLabel>
          <Select
            labelId="select-list-label"
            value={selectedList}
            onChange={(e) => setSelectedList(e.target.value)}
            label="Seleziona Lista"
          >
            <MenuItem value="neonati">Neonati</MenuItem>
            <MenuItem value="bambini">Bambini</MenuItem>
            <MenuItem value="beauty">Beauty</MenuItem>
            <MenuItem value="farmacia">Farmacia</MenuItem>
            <MenuItem value="nondimenticare">Non dimenticare</MenuItem>
          </Select>
        </FormControl>
        <TodoList todos={lists[selectedList]} removeTodo={removeTodo} toggleTodo={toggleTodo} />
        <TodoForm addTodo={addTodo} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack direction="row" spacing={1.5}>
            <Button variant="contained" sx={{ backgroundColor: '#112B68', color: '#ffffff' }} onClick={exportToExcel} className="export-button">
              DOWNLOAD
            </Button>
            <Button variant="contained" sx={{ backgroundColor: '#DD130C', color: '#ffffff' }} onClick={resetTodos} className="reset-button">
              RIPRISTINA
            </Button>
          </Stack>
        </Box>
      </Container>
      <div className="footer">
        <Typography variant="h6" sx={{ color: '#112B68' }}><InstagramIcon sx={{ color: "#112B68" }}></InstagramIcon>   Seguici qui: <Link href="https://www.instagram.com/sailing_shibumi/" underline="none" target="_blank" rel="noopener">@sailing_shibumi</Link> e <Link href="https://www.instagram.com/ilfrangente/" underline="none" target="_blank" rel="noopener">@ilfrangente</Link>
        </Typography>
      </div>
    </div>
  );
}

export default App;
